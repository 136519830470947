<template>
  <div class="component-recategorize">
    <b-container class="bg-light page-wrapper">

      <div class="main-content-wrapper">
        <b-row no-gutters>
          <b-col cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <h1 class="text-left">{{ $t(translationPath + 'heading') }}</h1>
          </b-col>
        </b-row>

        <b-row no-gutters>
          <b-col cols="12">
            <div class="step-container-v2">
              <div class="stepper bg-light mx-auto text-regular">

                <form-wizard :title="''" :subtitle="''" :stepSize="'xs'" :color="'#ffffff'"  :errorColor="'#ffffff'" ref="recategorizeSteps">
                  <tab-content :title="$t('common.edit')" :before-change="()=>validateStep('step1')">

                    <!-- Multi steps edit component -->
                    <multi-steps-edit
                      :transactions="aTransactions"
                      :categoryOptions="aAccountOptionsForCustomSelect"
                      @load-options="loadOptions"
                      @validate-success="update"
                      ref="step1"
                    />
                  </tab-content>
                  <tab-content :title="$t('common.confirm')">
                    <!-- Multi steps view component -->
                    <multi-steps-view  :transactionsView="aTransactions"/>
                  </tab-content>

                  <template slot="footer" slot-scope="props">
                    <div :class="{ hasSpace: props.activeTabIndex !== 0 }">
                      <div class="wizard-footer-left">
                        <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">
                          {{ $t('common.previous') }}
                        </wizard-button>
                      </div>
                      <div class="line" v-if="props.activeTabIndex !== 0"></div>
                      <div class="wizard-footer-right">
                        <wizard-button tabindex="5" v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle" :disabled="nextDisabled[props.activeTabIndex]">
                          {{ $t('common.next') }}
                        </wizard-button>
                        <wizard-button v-else @click.native="onComplete" class="wizard-footer-right finish-button" tabindex="5" :style="props.fillButtonStyle" :disabled="nextDisabled[props.activeTabIndex]">{{$t('common.save')}}</wizard-button>
                      </div>
                    </div>
                  </template>

                </form-wizard>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import MultiStepsView from './MultiSteps/MultiStepsView'
import MultiStepsEdit from './MultiSteps/MultiStepsEdit'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'RecategoriseMultiStep',
  components: {
    FormWizard,
    TabContent,
    WizardButton,
    MultiStepsView,
    MultiStepsEdit
  },
  data () {
    return {
      translationPath: 'transactions.re-categorize.',
      nextDisabled: {
        0: false,
        1: false
      },
      aOriginalTransactionsFromParam: [],
      aAccountOptionsForCustomSelect: [],
      aTransactions: []
    }
  },
  computed: {
    breadcrumbItems () {
      return [
        {
          text: this.$t('common.header.header_menu.transactions.transactions'),
          to: '/transactions/view'
        },
        {
          text: this.$t('transactions.submenu.view'),
          to: '/transactions/view'
        },
        { text: '', to: '', active: true }
      ]
    },
    finalTransactions () {
      if (!this.aTransactions || this.aTransactions.length === 0) return []
      return this.aTransactions.map(item => {
        const oTransactionEntryCredit = { account_id: item.credit_account ? item.credit_account.id : null, active_percentage: parseFloat(item.percentage) || null, dctype: 'cr', amount: item.amount || 0, description: null }
        const oTransactionEntryDebit = { account_id: item.debit_account ? item.debit_account.id : null, active_percentage: parseFloat(item.percentage) || null, dctype: 'dr', amount: item.amount || 0, description: null }
        const entries = [oTransactionEntryCredit, oTransactionEntryDebit]
        return Object.assign({}, { transaction_id: item.id, date: item.date ? moment(item.date).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'), title: item.title, type: item.type, entries: entries, description: item.transaction_description, tag_actions: item.tagActions })
      })
    }
  },
  created () {
    this.loadAccount('income,expense,asset,liability')
      .then((data) => {
        this.aAccountOptionsForCustomSelect = data
        this.aOriginalTransactionsFromParam = this.$route.params.transactions || []
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    onComplete () {
      const postData = { transactions: this.finalTransactions }

      axios.post(`${process.env.VUE_APP_ROOT_API}/transactions/re-categorize`, postData).then(() => {
        this.aTransactions = []
        this.aOriginalTransactionsFromParam = []
        this.$refs.recategorizeSteps.reset()
        this.$bvToast.toast(this.$t('transactions.view.toast.update_multiple_success_message').toString(), {
          title: this.$t(this.translationPath + 'heading').toString(),
          variant: 'success',
          solid: true,
          autoHideDelay: 5000
        })
        setTimeout(() => {
          this.$router.push('/transactions/view')
        }, 3000)
      }).catch(e => {
        console.error(e)
        this.$bvToast.toast(this.$t('transactions.view.toast.update_multiple_failed_message').toString(), {
          title: this.$t(this.translationPath + 'heading').toString(),
          variant: 'danger',
          solid: true,
          autoHideDelay: 5000
        })
      })
    },
    onCompleteOld () {
      const postData = { transactions: this.finalTransactions }
      axios.post(`${process.env.VUE_APP_ROOT_API}/transactions/re-categorize`, postData).then(() => {
        this.aTransactions = []
        this.aOriginalTransactionsFromParam = []
        this.$refs.recategorizeSteps.reset()
        this.$bvToast.toast(this.$t('transactions.view.toast.update_multiple_success_message').toString(), {
          title: this.$t(this.translationPath + 'heading').toString(),
          variant: 'success',
          solid: true,
          autoHideDelay: 5000
        })
        setTimeout(() => {
          this.$router.push('/transactions/view')
        }, 3000)
      }).catch(e => {
        console.error(e)
        this.$bvToast.toast(this.$t('transactions.view.toast.update_multiple_failed_message').toString(), {
          title: this.$t(this.translationPath + 'heading').toString(),
          variant: 'danger',
          solid: true,
          autoHideDelay: 5000
        })
      })
    },
    parseTransactions (el) {
      let selectedDebitAccount = []
      let selectedCreditAccount = []
      const allAccounts = _.flattenDeep(
        this.aAccountOptionsForCustomSelect.map(
          (innerEl) => innerEl.accounts
        )
      )
      selectedDebitAccount = allAccounts.filter((innerEl) => innerEl.id === el.dr_entry.account_id)
      selectedCreditAccount = allAccounts.filter((innerEl) => innerEl.id === el.cr_entry.account_id)
      return {
        id: el.id,
        title: el.title,
        amount: Math.abs(el.amount),
        date: el.date,
        errors: {},
        transaction_subtype: el.transaction_subtype,
        percentage: parseFloat(el.active_percentage),
        credit_account:
          selectedCreditAccount.length > 0 ? selectedCreditAccount[0] : null,
        debit_account: selectedDebitAccount.length > 0 ? selectedDebitAccount[0] : null,
        transaction_description: el.transaction_description,
        tags: _.cloneDeep(el.tags),
        tagsToShow: _.cloneDeep(el.tags),
        tagActions: { add_tag: [], remove_tag: [], replace_tag: {} }
      }
    },
    async validateStep (stepName) {
      const validationStatus = await this.$refs[stepName].validate()
      return validationStatus
    },
    update (value) {
      this.aTransactions = value
    },
    loadOptions () {
      this.loadAccount()
        .then(data => {
          this.aAccountOptionsForCustomSelect = data
        })
        .catch(err => {
          console.error(err)
        })
    },
    loadAccount () {
      return axios
        .get(
          `${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=income,expense,asset,liability&include_networth_account=1&log=ReCategorize`
        )
        .then((response) => response.data.data)
        .then((responseData) => {
          return responseData.map((items) => {
            return Object.assign(items, {
              label: this.$t('common.' + items.type.toLowerCase()) + ' / ' + items.label
            })
          })
        })
        .catch(err => {
          console.error(err)
          return {
            options: [],
            loading: false
          }
        })
    }
  },
  watch: {
    aOriginalTransactionsFromParam: {
      handler: function (val) {
        this.complexTransactionCount = val.filter(
          (el) => el.transaction_subtype === 'complex_transaction'
        ).length
        this.aTransactions = val
          .filter((el) => el.transaction_subtype !== 'complex_transaction')
          .map((el) => this.parseTransactions(el))
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/form-wizard-2.scss';
.component-recategorize{
  width: 100%;
  .wizard-tab-content{
    padding-left:0;
    padding-right:0;
  }
  h1 {
    color: #545b7a;
  }
  h2 {
    color: #545b7a;
  }
  h4 {
    color: #545b7a;
  }
  .FormLabels {
    color: #545b7a;
    font-weight: 600;
  }
  legend {
    color: #545b7a;
    font-weight: 600;
  }
  label {
    color: #545b7a;
    font-weight: 600;
  }
}
</style>
